import React, { useEffect, useRef, useState } from "react";
import "./ShopLanding.css";
import shopeglogo from "../../assets/shopeg_logo.svg";
import previousnext from "../../assets/previous_arrow.svg";
import next from "../../assets/next_arrow.svg";
import paytm from "../../assets/paytm.svg";
import secondapp from "../../assets/second.svg";
import meesho from "../../assets/meesho.svg";
import mystore from "../../assets/mystore.svg";
import paytmnav from "../../assets/paytm_nav.svg";
import secondappnav from "../../assets/secondapp_nav.svg";
import meeshonav from "../../assets/meesho_nav.svg";
import mystorenav from "../../assets/mystore_nav.svg";
import arrownext from "../../assets/arrow-button-right.svg";
import Slider from "react-slick";
import closebutton from "../../assets/close-button.svg";
import previous from "../../assets/left-arrow-green.svg";
import shed from "../../assets/shed.svg";
import CustomAlert from "../../components/CustomAlert";
import { firebaseAnalytics } from "../../utils/firebase_config";
import ShopNotFound from "../../components/ShopNotFound";
import mappls from "../../assets/mappls.svg";
import mapplsnav from "../../assets/mappls_nav.svg";

const apps = [
  { name: "Paytm", src: paytmnav, offer: "40% OFF upto ₹40" },
  { name: "Pincode", src: secondappnav },
  { name: "Meesho", src: meeshonav },
  { name: "Mystore", src: mystorenav, offer: "40% OFF upto ₹40" },
  { name: "Mappls", src: mapplsnav, offer: "25% OFF up to ₹45" },
];

const largeApps = [
  { name: "Paytm", src: paytm, offer: "40% OFF upto ₹40" },
  { name: "Pincode", src: secondapp },
  { name: "Meesho", src: meesho },
  { name: "Mystore", src: mystore, offer: "40% OFF upto ₹40" },
  { name: "Mappls", src: mappls, offer: "25% OFF up to ₹45" },
];

const IndividualCategory = ({ category, key }) => {
  return (
    <div className="single-category" key={key}>
      <p>{category?.key}</p>
    </div>
  );
};

const ShopLanding = () => {
  firebaseAnalytics.logEvent("SHOP_PAGE_VIEW");
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCategoryIndex, setCategoryCurrentIndex] = useState(0);
  const [shopData, setShopData] = useState(null);
  const [shopCategories, setShopCategories] = useState([]);
  const [shopOffers, setShopOffers] = useState([]);
  const [shopProducts, setShopProducts] = useState([]);
  const [shopCampaign, setShopCampaign] = useState([]);
  const [shopLoaded, setShopLoaded] = useState(false);
  let sliderRef = useRef(null);
  let categorySliderRef = useRef(null);
  const modalRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://prod-api.shopeg.in/api/client/shop/shopDetails/${
            window.location.pathname.split("/")[1]
          }`
        );
        const data = await response.json();
        const shopDetails = data?.result?.data;
        if (!shopDetails) {
          setShopLoaded(true);
        }
        setShopCampaign(shopDetails?.campaignResources);
        setShopData(shopDetails);
      } catch (error) {
        if (error) {
          setShopLoaded(true);
        }
        console.error("Error fetching shop data:", error);
      }
    };

    function detectDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/Windows|Macintosh|Linux/.test(userAgent)) {
        return "Desktop";
      }
      return "Unknown";
    }

    fetchData();
    detectDevice();
    const device = detectDevice();
    if (device === "iOS" || device === "Android") {
      const timeoutId = setTimeout(() => {
        setShowModal1(true);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  const shopId = shopData?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://prod-api.shopeg.in/api/client/admin/shop/inventory/getProductDetailsWithCategoriesAndOffers/${shopId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const inventoryDetails = data?.result?.data;
        const { categories, offers, products } = inventoryDetails;
        setShopCategories(categories);
        setShopOffers(offers);
        setShopProducts(products);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [shopId]);

  const nextCategory = () => {
    if (currentCategoryIndex < shopCategories?.length - 1) {
      sliderRef?.slickNext();
    }
  };
  const previousCategory = () => {
    if (currentCategoryIndex > 0) {
      sliderRef?.slickPrev();
    }
  };

  const previousOffer = () => {
    if (currentIndex > 0) {
      categorySliderRef?.slickPrev();
    }
  };

  const nextOffer = () => {
    if (currentIndex < shopCampaign?.length - 1) {
      categorySliderRef?.slickNext();
    }
  };

  const handleAfterChange = (index) => {
    setCurrentIndex(index);
  };

  const handleCategoryAfterChange = (index) => {
    setCategoryCurrentIndex(index);
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal1(false);
      setShowModal2(false);
    }
  };

  useEffect(() => {
    if (showModal1) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else if (showModal2) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showModal1, showModal2]);

  let categorySettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    cssEase: "linear",
    arrows: false,
    draggable: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    afterChange: handleAfterChange,
  };

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 2,
    autoplay: false,
    cssEase: "linear",
    arrows: false,
    draggable: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 500,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          speed: 500,
        },
      },
    ],
    afterChange: handleCategoryAfterChange,
  };

  const allApps = shopData?.buyerAppLinks;

  const mobileApps = shopData?.buyerAppLinks?.filter(
    (app) => app?.type === "mobile" || app?.type === "both"
  );

  const webApps = shopData?.buyerAppLinks?.filter(
    (app) => app?.type === "both" || app?.type === "web"
  );

  function detectDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/Windows|Macintosh|Linux/.test(userAgent)) {
      return "Desktop";
    }
    return "Unknown";
  }

  const geopoint = {
    lat: shopData?.loc?.geopoint?.lat,
    lon: shopData?.loc?.geopoint?.lon,
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const truncateName = (name) => {
    if (name.length > 80) {
      return name.substring(0, 40) + " ...";
    }
    return name;
  };

  return (
    <>
      {shopLoaded ? (
        <ShopNotFound />
      ) : (
        <div className="main-div">
          <div className="top-main">
            <div className="topleft">
              <img src={shopeglogo} alt="logo" />
              <p>ShopEG</p>
            </div>
            <div className="topright">
              {allApps && <h3 className="ordertitle">Order From</h3>}
              <div className="mobile-apps">
                {allApps &&
                  allApps?.map((app) => {
                    const matchedApp = apps?.find((a) => a?.name === app?.name);
                    const handleClick = (event) => {
                      firebaseAnalytics.logEvent(
                        "SHOP_PAGE_CLICK_BUYER_APP_WEB_NAVBAR",
                        {
                          appLink: app?.url,
                        }
                      );
                      if (
                        detectDevice() === "Desktop" &&
                        app?.type === "mobile"
                      ) {
                        event.preventDefault();
                        setShowAlert(true);
                      }
                    };
                    return (
                      <a
                        href={app?.url}
                        target="_blank"
                        key={app?.name}
                        onClick={handleClick}
                      >
                        <img
                          className="nav-logo"
                          src={matchedApp?.src}
                          alt=""
                        />
                      </a>
                    );
                  })}
              </div>
              {allApps && (
                <button
                  className="order-button"
                  onClick={() => {
                    firebaseAnalytics.logEvent(
                      "SHOP_PAGE_CLICK_BUYER_APP_MOB_NAVBAR"
                    );
                    setShowModal1((prev) => !prev);
                  }}
                  disabled={allApps?.length === 0 || !allApps}
                >
                  Order Now
                </button>
              )}
            </div>
          </div>
          <div className="body-main">
            <div className="shop-image">
              <div className="main-image">
                <div className="shop-name">{shopData?.shop_name}</div>
                <div className="shed-img">
                  <img src={shed} alt="" />
                </div>
                {shopData && (
                  <div className="image-div">
                    <img src={shopData?.shop_image} alt="shop" />
                  </div>
                )}
              </div>
              <div className="shop-location">
                {shopData?.addressData && (
                  <>
                    <p>Address</p>
                    {shopData && (
                      <div className="shop-address">
                        {`${shopData?.addressData?.name}, ${shopData?.addressData?.address}, ${shopData?.addressData?.address2}, ${shopData?.addressData?.city}, ${shopData?.addressData?.state}, ${shopData?.addressData?.country}, ${shopData?.addressData?.zipCode}`}
                      </div>
                    )}
                  </>
                )}
                <div className="location-area">
                  <iframe
                    className="map-view"
                    key={`${geopoint.lat}-${geopoint.lon}`}
                    src={`https://maps.google.com/maps?q=${geopoint.lat},${geopoint.lon}&z=15&ie=UTF8&iwloc=&output=embed`}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            {shopCampaign?.length > 0 && (
              <div className="offer-main">
                <div className="offer-top">
                  <div className="nav-button">
                    <img
                      src={currentIndex === 0 ? previousnext : previous}
                      alt="previous"
                      onClick={previousOffer}
                    />
                    <img
                      src={
                        (width > 1023 && shopCampaign?.length <= 3) ||
                        currentIndex ===
                          (width > 1023
                            ? shopCampaign?.length - 3
                            : width >= 768 && width <= 1023
                            ? shopCampaign?.length - 1
                            : shopCampaign?.length - 1)
                          ? arrownext
                          : next
                      }
                      alt="next"
                      onClick={
                        (width >= 1024
                          ? shopCampaign?.length > 3
                          : shopCampaign?.length > 1) && nextOffer
                      }
                    />
                  </div>
                </div>
                <div className="offer-bottom">
                  <Slider
                    {...categorySettings}
                    ref={(slider) => {
                      categorySliderRef = slider;
                    }}
                    initialSlide={0}
                  >
                    {shopCampaign
                      ?.filter((offer) => {
                        const fileExtension = offer
                          .split(".")
                          .pop()
                          .toLowerCase();
                        return (
                          fileExtension === "jpg" ||
                          fileExtension === "png" ||
                          fileExtension === "jpeg" ||
                          fileExtension === "mp4" ||
                          fileExtension === "webm"
                        );
                      })
                      .map((offer, index) => {
                        const fileExtension = offer
                          .split(".")
                          .pop()
                          .toLowerCase();
                        return (
                          <div className="single-offer" key={index}>
                            {(fileExtension === "jpg" ||
                              fileExtension === "png" ||
                              fileExtension === "jpeg") && (
                              <img
                                src={offer}
                                alt={`offer ${index}`}
                                className="offer-content"
                              />
                            )}

                            {(fileExtension === "mp4" ||
                              fileExtension === "webm") && (
                              <video controls className="video-content">
                                <source
                                  src={offer}
                                  type={`video/${fileExtension}`}
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            )}
            {shopOffers?.length > 0 && (
              <>
                <div className="offer-title">Offers</div>
                <div className="offer-cards">
                  {shopOffers?.map((offers, index) => (
                    <div
                      key={index}
                      className="first-card"
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#aa8ef8" : "#d7f2d2",
                        color: index % 2 === 0 ? "#402d9a" : "#255453",
                      }}
                    >
                      <div className="offer-desc">
                        <h2>{offers?.title}</h2>
                        <p>Use: {offers?.coupon_code}</p>
                        <button
                          className="left-offer"
                          onClick={() => {
                            firebaseAnalytics.logEvent(
                              "SHOP_PAGE_CLICK_SHOP_NOW_OFFER"
                            );
                            setShowModal1((prev) => !prev);
                          }}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#402d9a" : "#255453",
                          }}
                          disabled={allApps?.length === 0 || !allApps}
                        >
                          Shop Now
                        </button>
                      </div>
                      <div className="offer-image">
                        <img src={offers?.descriptor?.images[0]} alt="offer" />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {shopCategories?.length > 0 && (
              <div className="category-div">
                <div className="category-top">
                  <div className="category-title">Shop By Category</div>
                  <div className="category-nav-button">
                    <img
                      src={currentCategoryIndex === 0 ? previousnext : previous}
                      onClick={previousCategory}
                      alt="previous"
                    />
                    <img
                      src={
                        (width > 1023 && shopCategories?.length <= 9) ||
                        currentCategoryIndex ===
                          (width > 1023
                            ? shopCategories?.length - 9
                            : width >= 768 && width <= 1023
                            ? shopCategories?.length - 3
                            : shopCategories?.length - 2)
                          ? arrownext
                          : next
                      }
                      onClick={shopCategories?.length > 9 && nextCategory}
                      alt="next"
                    />
                  </div>
                </div>
                <div className="all-categories">
                  <Slider
                    {...settings}
                    ref={(slider) => {
                      sliderRef = slider;
                    }}
                  >
                    {shopCategories?.map((category, index) => (
                      <IndividualCategory category={category} key={index} />
                    ))}
                  </Slider>
                </div>
              </div>
            )}
            {shopProducts?.length > 0 && (
              <div className="product-div">
                <div className="product-heading">Products</div>
                <div className="all-products">
                  {shopProducts?.map((product, index) => (
                    <div className="single-product" key={index}>
                      {product?.price?.maximum_value - product?.price?.value !==
                        0 && (
                        <div className="product-offer-div">
                          {parseFloat(
                            ((product?.price?.maximum_value -
                              product?.price?.value) /
                              product?.price?.maximum_value) *
                              100
                          ).toFixed(2)}{" "}
                          % off
                        </div>
                      )}
                      <div className="product-image">
                        <img
                          src={product?.descriptor?.images[0]}
                          alt="product"
                        />
                      </div>
                      <div className="product-desc">
                        {/* <p className="product-type-quan">
                          {product?.productType}
                        </p> */}
                        <p
                          className="product-name"
                          title={product?.descriptor?.name}
                        >
                          {truncateName(product?.descriptor?.name)}
                        </p>
                      </div>
                      <p className="product-type-quan">
                        {`${product?.quantity?.unitized?.measure?.value} 
                    ${product?.quantity?.unitized?.measure?.unit}`}
                      </p>
                      <div className="product-price">
                        {product?.price?.maximum_value &&
                        product?.price?.maximum_value !==
                          product?.price?.value ? (
                          <>
                            <p className="strike-price">
                              ₹{product?.price?.maximum_value}
                            </p>
                            <p>₹{product?.price?.value}</p>
                          </>
                        ) : (
                          <p className="rupee-offer-div">
                            ₹{product?.price?.value}
                          </p>
                        )}
                      </div>
                      <div className="product-order-button-div">
                        <button
                          className={`product-order-button ${
                            allApps?.length === 0 || !allApps
                              ? "disabled-button"
                              : ""
                          }`}
                          onClick={() => {
                            firebaseAnalytics.logEvent(
                              "SHOP_PAGE_CLICK_PRODUCTS_ORDER_NOW"
                            );
                            // firebaseAnalytics.logEvent(
                            //   `${product?.descriptor?.name}`
                            // );
                            firebaseAnalytics.logEvent(
                              "PRODUCT_OREDER_NOW_CLICKED",
                              {
                                content_type: "product",
                                content_id: `${product?.id}`,
                              }
                            );
                            setShowModal2((prev) => !prev);
                          }}
                          disabled={allApps?.length === 0 || !allApps}
                        >
                          Order Now
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="mobile-shop-location">
              <p className="mobile-address">Address</p>
              {shopData && (
                <div className="shop-address">
                  {`${shopData?.addressData?.name}, ${shopData?.addressData?.address}, ${shopData?.addressData?.address2}, ${shopData?.addressData?.city}, ${shopData?.addressData?.state}, ${shopData?.addressData?.country}, ${shopData?.addressData?.zipCode}`}
                </div>
              )}
              <div className="location-area">
                <iframe
                  className="map-view"
                  key={`${geopoint.lat}-${geopoint.lon}`}
                  src={`https://maps.google.com/maps?q=${geopoint.lat},${geopoint.lon}&z=15&ie=UTF8&iwloc=&output=embed`}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className={`footer-main ${!allApps ? "no-left" : ""}`}>
              {allApps && (
                <div className="footer-left">
                  <div className="footer-left-heading">
                    <p>Order Now On</p>
                  </div>
                  {allApps?.length > 0 && (
                    <div className="nav-apps">
                      {allApps &&
                        allApps?.map((app) => {
                          const matchedApp = largeApps?.find(
                            (a) => a?.name === app?.name
                          );
                          const handleClick = (event) => {
                            firebaseAnalytics.logEvent(
                              "SHOP_PAGE_CLICK_BUYER_APP_FOOTER",
                              {
                                appLink: app?.url,
                              }
                            );
                            if (
                              detectDevice() === "Desktop" &&
                              app?.type === "mobile"
                            ) {
                              event.preventDefault();
                              setShowAlert(true);
                            }
                          };
                          return (
                            <a
                              href={app?.url}
                              target="_blank"
                              key={app?.name}
                              onClick={handleClick}
                            >
                              <img src={matchedApp?.src} alt="" />
                            </a>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
              <div className="footer-right">
                <div className="copy-right">
                  <p>
                    © Copyright Sparketernal Technologies Pvt Ltd. All Rights
                    Reserved
                  </p>
                </div>
                <div className="address-div">
                  <p>
                    Mahalakshmi Chambers, 9, MG Road, Opp. Trinity Metro
                    Station, Bangalore, 560001
                  </p>
                </div>
              </div>
            </div>
          </div>

          {showModal1 && (
            <>
              <div className="blur-overlay"></div>
              <div className="button-apps4" ref={modalRef}>
                <div className="top-popup">
                  <p>Order Online</p>
                  <img
                    src={closebutton}
                    alt=""
                    onClick={() => setShowModal1(false)}
                  />
                </div>
                {webApps && (
                  <div className="web-apps">
                    <p className="web-heading">Shop On Web</p>
                    {webApps?.map((app) => {
                      const matchedApp = apps?.find(
                        (a) => a?.name === app?.name
                      );
                      const handleClick = () => {
                        firebaseAnalytics.logEvent(
                          "SHOP_PAGE_CLICK_SHOP_NOW_OFFER",
                          {
                            appLink: app?.url,
                          }
                        );
                      };
                      return (
                        <a
                          href={app?.url}
                          target="_blank"
                          key={app?.name}
                          onClick={handleClick}
                        >
                          <div className="individual-apps" key={app?.name}>
                            <div className="name-logo">
                              {matchedApp && (
                                <img src={matchedApp?.src} alt={app?.name} />
                              )}
                              <p>{app?.name}</p>
                            </div>
                            {matchedApp?.offer ? (
                              <p className="offer-text">{matchedApp.offer}</p>
                            ) : null}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                )}
                {mobileApps && (
                  <div>
                    <p className="web-heading">Shop On Mobile</p>
                    {mobileApps?.map((app) => {
                      const matchedApp = apps?.find(
                        (a) => a?.name === app?.name
                      );
                      const handleClick = (event) => {
                        firebaseAnalytics.logEvent(
                          "SHOP_PAGE_CLICK_SHOP_NOW_OFFER",
                          {
                            appLink: app?.url,
                          }
                        );
                        if (
                          detectDevice() === "Desktop" &&
                          app?.type === "mobile"
                        ) {
                          event.preventDefault();
                          setShowAlert(true);
                        }
                      };
                      return (
                        <a
                          href={app?.url}
                          target="_blank"
                          key={app?.name}
                          onClick={handleClick}
                        >
                          <div className="individual-apps">
                            <div className="name-logo">
                              {matchedApp && (
                                <img src={matchedApp?.src} alt={app?.name} />
                              )}
                              <p>{app?.name}</p>
                            </div>
                            {matchedApp?.offer ? (
                              <p className="offer-text">{matchedApp.offer}</p>
                            ) : null}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                )}
                {showAlert && (
                  <CustomAlert
                    message="This site can only be accessed through mobile."
                    onClose={handleCloseAlert}
                  />
                )}
                <div className="condition-div">
                  <p>Discounts are subjected to buyer app terms</p>
                </div>
              </div>
            </>
          )}
          {showModal2 && (
            <>
              <div className="blur-overlay"></div>
              <div className="button-apps4" ref={modalRef}>
                <div className="top-popup">
                  <p>Order Online</p>
                  <img
                    src={closebutton}
                    alt=""
                    onClick={() => setShowModal2(false)}
                  />
                </div>
                {webApps && (
                  <div className="web-apps">
                    <p className="web-heading">Shop On Web</p>
                    {webApps?.map((app) => {
                      const matchedApp = apps?.find(
                        (a) => a?.name === app?.name
                      );
                      const handleClick = () => {
                        firebaseAnalytics.logEvent(
                          "SHOP_PAGE_CLICK_PRODUCTS_ORDER_NOW",
                          {
                            appLink: app?.url,
                          }
                        );
                      };
                      return (
                        <a
                          href={app?.url}
                          target="_blank"
                          key={app?.name}
                          onClick={handleClick}
                        >
                          {" "}
                          <div className="individual-apps" key={app?.name}>
                            <div className="name-logo">
                              {matchedApp && (
                                <img src={matchedApp?.src} alt={app?.name} />
                              )}
                              <p>{app?.name}</p>
                            </div>
                            {matchedApp?.offer ? (
                              <p className="offer-text">{matchedApp.offer}</p>
                            ) : null}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                )}
                {mobileApps && (
                  <div>
                    <p className="web-heading">Shop On Mobile</p>
                    {mobileApps?.map((app) => {
                      const matchedApp = apps?.find(
                        (a) => a?.name === app?.name
                      );
                      const handleClick = (event) => {
                        firebaseAnalytics.logEvent(
                          "SHOP_PAGE_CLICK_PRODUCTS_ORDER_NOW",
                          {
                            appLink: app?.url,
                          }
                        );
                        if (
                          detectDevice() === "Desktop" &&
                          app?.type === "mobile"
                        ) {
                          event.preventDefault();
                          setShowAlert(true);
                        }
                      };
                      return (
                        <a
                          href={app?.url}
                          target="_blank"
                          key={app?.name}
                          onClick={handleClick}
                        >
                          <div className="individual-apps">
                            <div className="name-logo">
                              {matchedApp && (
                                <img src={matchedApp?.src} alt={app?.name} />
                              )}

                              <p>{app?.name}</p>
                            </div>
                            {matchedApp?.offer ? (
                              <p className="offer-text">{matchedApp.offer}</p>
                            ) : null}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                )}
                {showAlert && (
                  <CustomAlert
                    message="This site can only be accessed through mobile."
                    onClose={handleCloseAlert}
                  />
                )}
                <div className="condition-div">
                  <p>Discounts are subjected to buyer app terms</p>
                </div>
              </div>
            </>
          )}
          {showAlert && (
            <CustomAlert
              message="This site can only be accessed through mobile."
              onClose={handleCloseAlert}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ShopLanding;
